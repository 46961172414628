import { ChangeDetectionStrategy, Component, effect, input, output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkNestedTreeNode, CdkTree, CdkTreeNodeDef, CdkTreeNodeOutlet } from '@angular/cdk/tree';
import { HierarchyNodeComponent } from '@suite/hierarchy-node';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { HierarchyNode } from '@suite/hierarchy-data-access';
import { Favorite } from '@suite/favorite';

@Component({
  selector: 'lib-full-hierarchy',
  standalone: true,
  imports: [
    CommonModule,
    CdkNestedTreeNode,
    CdkTree,
    CdkTreeNodeDef,
    CdkTreeNodeOutlet,
    HierarchyNodeComponent,
    TranslateModule,
    RouterLink,
  ],
  templateUrl: './full-hierarchy.component.html',
  styleUrl: './full-hierarchy.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullHierarchyComponent {
  hierarchy = input.required<HierarchyNode[]>();
  favorites = input<Map<string, Favorite>>(new Map<string, Favorite>());
  selectedNode = input<HierarchyNode | null>(null);
  showConfigMenu = input<boolean>();
  selectNode = output<HierarchyNode>();
  toggleFavorite = output<HierarchyNode>();

  childrenAccessor = (hierarchyNode: HierarchyNode) => hierarchyNode.children ?? [];

  hasChild = (_: number, hierarchyNode: HierarchyNode) => hierarchyNode.hasChild();

  @ViewChild('fullTree') fullTree!: CdkTree<HierarchyNode>;

  constructor() {
    effect(() => {
      this.expand(this.selectedNode());
    });
  }

  expand = (hierarchyNode: HierarchyNode | null) => {
    if (!hierarchyNode) {
      return;
    }

    if (hierarchyNode.parent) {
      this.fullTree.expand(hierarchyNode.parent);
      this.expand(hierarchyNode.parent);
    }
  };
}
