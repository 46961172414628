import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  NgZone,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, CommonModule } from '@angular/common';

type HandleType = 'icon' | 'line' | 'none';

@Component({
  selector: 'lib-collapse-panel',
  standalone: true,
  template: `
    <aside class="relative flex h-full w-full" [class.flex-row-reverse]="openFrom() === 'right'">
      <div class="overflow-hidden" [ngClass]="{ 'transition-all': !dragging() }" [style.width.px]="width$ | async">
        <ng-content></ng-content>
      </div>

      @switch (handleType()) {
        @case ('icon') {
          <div
            class="item-center shadow-noova absolute top-1/2 flex justify-center rounded-lg bg-white text-surface-600 transition-all hover:scale-150 hover:text-surface-700 active:text-surface-600"
            [ngClass]="getIconDragHandleClasses()"
            cdkDrag
            cdkDragLockAxis="x"
            (cdkDragMoved)="dragMoved($event)"
            (cdkDragEnded)="dragEnded()"
            (click)="togglePanel()">
            @if (isOpen()) {
              <svg-icon class="cursor-ew-resize" size="sm" key="handle-horizontal"></svg-icon>
            } @else {
              <svg-icon
                class="cursor-pointer"
                size="sm"
                [key]="openFrom() === 'left' ? 'handle-chevron-right' : 'handle-chevron-left'">
              </svg-icon>
            }
          </div>
        }
        @case ('line') {
          <div
            class="absolute flex h-full w-1 cursor-ew-resize items-center rounded-lg bg-primary-50 transition-all hover:scale-x-100 hover:bg-primary-100 dark:bg-primary-900 dark:hover:bg-primary-800"
            [ngClass]="getLineDragHandleClasses()"
            cdkDrag
            cdkDragLockAxis="x"
            (cdkDragMoved)="dragMoved($event)"
            (cdkDragEnded)="dragEnded()"></div>
        }
        @case ('none') {}
      }
    </aside>
  `,
  styles: [
    `
      :host {
        display: flex;
        height: 100%;
      }
    `,
  ],
  imports: [CommonModule, CdkDrag, SvgIconComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsePanelComponent implements OnInit {
  private ngZone = inject(NgZone);
  private el = inject(ElementRef);

  // Inputs
  handleType = input<HandleType>('icon');
  alwaysOpen = input<boolean>(false);
  minWidth = input<number>(150);
  initialWidth = input<number>(320);
  maxWidth = input<number>(600);
  openFrom = input<'left' | 'right'>('left');

  // External state for hide / show panel.
  visible = input<boolean>(true);

  // Outputs
  opened = output<boolean>();

  // Internal state for hide / show panel.
  private open = signal<boolean>(true);
  dragging = signal<boolean>(false);
  width$ = new BehaviorSubject<number>(0);

  // Computed state combining internal and external open states
  isOpen = computed(() => this.visible() && this.open());

  constructor() {
    // Effect to update width when open state changes
    effect(() => {
      this.width$.next(this.isOpen() ? this.initialWidth() : 0);
    });
  }

  ngOnInit() {
    this.width$.next(this.isOpen() ? this.initialWidth() : 0);
  }

  private get resizeEl(): HTMLElement {
    return this.el.nativeElement;
  }

  getIconDragHandleClasses(): { [key: string]: boolean } {
    return {
      'left-0': this.openFrom() === 'right',
      'right-0': this.openFrom() === 'left',
      '!translate-x-2.5': this.openFrom() === 'left',
      '!-translate-x-2.5': this.openFrom() === 'right',
      '!-translate-y-2.5': true,
    };
  }

  getLineDragHandleClasses(): { [key: string]: boolean } {
    return {
      'left-0': this.openFrom() === 'right',
      'right-0': this.openFrom() === 'left',
      '!translate-x-0': this.openFrom() === 'left',
      '!-translate-x-0': this.openFrom() === 'right',
    };
  }

  togglePanel() {
    if (this.alwaysOpen()) {
      return;
    }

    if (this.dragging()) {
      return;
    }

    this.open.update((state) => !state);
    this.opened.emit(this.isOpen());

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  dragMoved(event: any) {
    this.open.set(true);
    this.dragging.set(true);

    this.ngZone.runOutsideAngular(() => {
      const newWidth = this.calculateNewWidth(event);
      this.width$.next(this.clampWidth(newWidth));
    });
  }

  dragEnded() {
    setTimeout(() => {
      this.dragging.set(false);
      window.dispatchEvent(new Event('resize')); // Inform dashboard to redraw
    }, 0);
  }

  private calculateNewWidth(event?: any): number {
    const rect = this.resizeEl.getBoundingClientRect();
    return this.openFrom() === 'left' ? event.pointerPosition.x - rect.left : rect.right - event.pointerPosition.x;
  }

  private clampWidth(width: number): number {
    return Math.min(Math.max(this.minWidth(), width), this.maxWidth());
  }
}
