<!-- TODO(bjhandeland): Customer nodes should not be different from reg. nodes. -->
@if (hierarchyNode().type === 'customer') {
  <div
    class="group/node flex cursor-pointer items-center justify-between rounded-lg p-1 hover:bg-surface-100 transition-all duration-100 dark:hover:bg-surface-700"
    [ngClass]="{'bg-surface-100 dark:bg-surface-700': isSelected()}"
    [routerLink]="[]">
    <div class="flex flex-row items-center gap-1 min-w-0 flex-grow h-8 pl-1">
      <p class="truncate min-w-0 flex-grow">{{ hierarchyNode().name }}</p>
    </div>

    @if (showConfigMenu()) {
      <div class="flex-shrink-0 ml-1">
        @if (hover() && visibleMenuItems().length) {
          <div
            class="flex items-center justify-center h-8 w-8 rounded-lg hover:bg-surface-300 dark:hover:bg-surface-600"
            (click)="menu.toggle($event); $event.stopPropagation();">
            <svg-icon key="plus"></svg-icon>
          </div>
        }
      </div>
    }
  </div>
} @else {
  <div
    class="group/node flex cursor-pointer items-center justify-between rounded-lg p-1 hover:bg-surface-100 transition-all duration-100 dark:hover:bg-surface-700"
    [ngClass]="{'bg-surface-100 dark:bg-surface-700': isSelected()}"
    (click)="selectNode.emit()">
    <div class="flex flex-row items-center gap-1 min-w-0 flex-grow">
      <div class="flex-shrink-0">
        @if (hover() && hasChild()) {
          <div
            class="group/expand invisible flex h-8 w-8 items-center justify-center rounded-lg hover:bg-surface-300 dark:hover:bg-surface-600 group-hover/node:visible"
            (click)="toggleNode.emit(); $event.stopPropagation()">
            <svg-icon key="chevron-down"></svg-icon>
          </div>
        } @else {
          <lib-hierarchy-node-icon
            [parentNodeType]="hierarchyNode().parent?.type"
            [hierarchyNodeType]="hierarchyNode().type">
          </lib-hierarchy-node-icon>
        }
      </div>

      <p class="truncate min-w-0 flex-grow">{{ hierarchyNode().name }}</p>
    </div>

    @if (showConfigMenu()) {
      <div class="flex-shrink-0 ml-1">
        @if (hover() && visibleMenuItems().length) {
          <div
            class="flex items-center justify-center h-8 w-8 rounded-lg hover:bg-surface-300 dark:hover:bg-surface-600"
            (click)="menu.toggle($event); $event.stopPropagation();">
            <svg-icon key="plus"></svg-icon>
          </div>
        }
      </div>
    }

    <div class="flex-shrink-0 ml-1">
      @if (hover() || isFavorite()) {
        <lib-hierarchy-favorite-icon
          [isFavorite]="isFavorite()"
          (toggleFavorite)="toggleFavorite.emit(hierarchyNode())">
        </lib-hierarchy-favorite-icon>
      }
    </div>
  </div>
}

<p-menu #menu [model]="visibleMenuItems()" [popup]="true" appendTo="body">
  <ng-template pTemplate="item" let-item>
    <a class="flex items-center p-menu-item-link"
       [routerLink]="[]"
       [queryParams]="item.queryParams">
      <svg-icon [key]="item.icon"></svg-icon>
      <span class="ml-2">{{ item.label }}</span>
    </a>
  </ng-template>
</p-menu>

