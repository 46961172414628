<nav id="full-hierarchy">
  <cdk-tree
    #fullTree
    [dataSource]="hierarchy()"
    [childrenAccessor]="childrenAccessor">
    <!-- Leaf nodes -->
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" [isExpandable]="false">
      <lib-hierarchy-node
        class="mb-1 flex flex-col"
        [hierarchyNode]="node"
        [selectedNode]="selectedNode()"
        [favorites]="favorites()"
        [showConfigMenu]="showConfigMenu()"
        (selectNode)="selectNode.emit(node)"
        (toggleFavorite)="toggleFavorite.emit(node)">
      </lib-hierarchy-node>
    </cdk-nested-tree-node>

    <!-- Expandable nodes -->
    <cdk-nested-tree-node
      *cdkTreeNodeDef="let node; when: hasChild"
      [isExpanded]="node.type === 'customer'"
      [isExpandable]="true">

      <lib-hierarchy-node
        class="mb-1 flex flex-col"
        [hierarchyNode]="node"
        [selectedNode]="selectedNode()"
        [favorites]="favorites()"
        [showConfigMenu]="showConfigMenu()"
        (selectNode)="selectNode.emit(node)"
        (toggleNode)="fullTree.toggle(node)"
        (toggleFavorite)="toggleFavorite.emit(node)">
      </lib-hierarchy-node>

      @if (fullTree.isExpanded(node)) {
        <div class="pl-4" [ngClass]="{'pl-4': node.type !== 'customer'}">
          <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
      }
    </cdk-nested-tree-node>
  </cdk-tree>
</nav>
